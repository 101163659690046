import { defineStore } from 'pinia'

export const useUserStoreV2 = defineStore('userv2', {
  state: () => {
    const teste = loadUser()
    // console.log(teste)
    return {
      ...teste,
    }
  },

  actions: {
    toggle2FA() {
      this.is2FAEnabled = !this.is2FAEnabled
    },

    changeUserName(userName: string) {
      this.userName = userName
    },
    setUser(user: any) {
      localStorage.setItem('userv2', JSON.stringify(user))
    },
    loadUser() {
      return loadUser()
    },
    removeUser(){
      localStorage.removeItem('userv2')
    }
  },
})
export function loadUser() {
  const user = localStorage.getItem('userv2')
  if (user) {
    return JSON.parse(user)
  }
  return null
}
