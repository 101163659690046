import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

import RouteViewComponent from '../layouts/RouterBypass.vue'
import { getCurrentUser } from '../services/authService'
import { useUserStoreV2 } from '../stores/user-store-v2'
// http://rb-front.s3-website-us-east-1.amazonaws.com/delete-account
const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'login' },
  },
  {
    path: '/delete-account',
    name: 'DeleteAccount',
    component: () => import('../pages/deleteAccount/DeleteAccountPage.vue'),
  },
  {
    path: '/',
    redirect: { name: 'dashboard' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'users',
        path: 'users',
        component: () => import('../pages/users-group/UsersPage.vue'),
      },
      {
        name: 'vendas',
        path: 'vendas',
        component: () => import('../pages/sales/SalesPage.vue'),
      },
      // {
      //   name: 'produtos',
      //   path: 'produtos',
      //   component: () => import('../pages/products/SalesPage.vue'),
      // },
      {
        name: 'contasareceber',
        path: 'contasareceber',
        component: () => import('../pages/billsToReceive/BillsToReceive.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'eventos',
        path: 'eventos',
        component: () => import('../pages/sales/SalesPage.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/Settings.vue'),
      },
      {
        name: 'preferences',
        path: 'preferences',
        component: () => import('../pages/preferencesv2/Preferences.vue'),
      },
      {
        name: 'projects',
        path: 'projects',
        component: () => import('../pages/projects/ProjectsPage.vue'),
      },
      {
        name: 'produtos',
        path: '/produtos',
        component: RouteViewComponent,
        children: [
          {
            name: 'list-products',
            path: 'list-products',
            component: () => import('../pages/products/ProductsPage.vue'),
          },
          {
            name: 'registration-product',
            path: 'registration',
            component: () => import('../pages/products/widgets/create-product/CreateProduct-manut.vue'),
          },
          {
            path: 'edit-product/:id',
            name: 'ProductEdit',
            component: () => import('../pages/products/widgets/create-product/EditProduct.vue'),
            props: true,
            exact: true,
          },
        ],
      },
      {
        name: 'contasareceberv2',
        path: '/contasareceberv2',
        component: RouteViewComponent,
        children: [
          {
            name: 'finance',
            path: 'finance',
            component: () => import('../pages/billsToReceive/BillsToReceive.vue'),
          },
          {
            name: 'transfer',
            path: 'transfer',
            component: () => import('../pages/transfer/Transfer-manu.vue'),
          },
        ],
      },
      // {
      //   name: 'eventos',
      //   path: '/eventos',
      //   component: RouteViewComponent,
      //   children: [
      //     {
      //       name: 'pricing-plans',
      //       path: 'pricing-plans',
      //       component: () => import('../pages/pricing-plans/PricingPlans.vue'),
      //     },
      //     {
      //       name: 'registration-events',
      //       path: 'registration',
      //       component: () => import('../pages/events/widgets/create-product/CreateEvents.vue'),
      //     },
      //   ],
      // },
       {
        name: 'payments',
        path: '/payments',
        component: RouteViewComponent,
        children: [
          {
            name: 'payment-methods',
            path: 'payment-methods',
            component: () => import('../pages/payments/PaymentsPage.vue'),
          },
          {
            name: 'billing',
            path: 'billing',
            component: () => import('../pages/billing/BillingPage.vue'),
          },
          {
            name: 'pricing-plans',
            path: 'pricing-plans',
            component: () => import('../pages/pricing-plans/PricingPlans.vue'),
          },
        ],
      },
      {
        name: 'payments',
        path: '/payments',
        component: RouteViewComponent,
        children: [
          {
            name: 'payment-methods',
            path: 'payment-methods',
            component: () => import('../pages/payments/PaymentsPage.vue'),
          },
          {
            name: 'billing',
            path: 'billing',
            component: () => import('../pages/billing/BillingPage.vue'),
          },
          {
            name: 'pricing-plans',
            path: 'pricing-plans',
            component: () => import('../pages/pricing-plans/PricingPlans.vue'),
          },
        ],
      },
      {
        name: 'faq',
        path: '/faq',
        component: () => import('../pages/faq/FaqPage.vue'),
      },
    ],
    meta: {
      //TODO ARRUMAR
      requiresAuth: true,
    },
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

//TODO ARRUMAR deslogar do firebase
router.beforeEach((to, from, next) => {
  const user = localStorage.getItem('userv2')
  let teste: any = null
  if (user) {
    teste = JSON.parse(user)
  }
  const currentUser = teste
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  if (requiresAuth && !currentUser) {
    next('/auth')
  } else if (!requiresAuth && currentUser) {
    next('/dashboard')
  } else {
    next()
  }
})

export default router
