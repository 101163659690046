<template>
  <VaSelect
    v-model="selectedOption"
    :options="options"
    track-by="id"
    :text-by="(option) => option.name"
    placeholder="Selecione o grupo"
  />
</template>

<script>
import { ref, onMounted, watch } from 'vue'
import axios from 'axios'
import { useUserStoreV2 } from '../../../stores/user-store-v2'
import { groupUserStore } from '../../../stores/group-store'
import { useGlobalStore } from '../../../stores/global-store'

export default {
  data() {
    const selectedOption = ref(null)
    const options = ref([])

    const globalStore = useGlobalStore()
    const userStore = useUserStoreV2()
    const groupUser = groupUserStore()

    const fetchOptions = async () => {
      const user = userStore.loadUser()
      const groupSelected = groupUser.loadUser()
      try {
        const response = await axios.get(
          `https://rb-api-usuarios-vjzqzydi4q-uc.a.run.app/api/v1/users/${user.uid}/groups`,
        )
        // options.value = response.data

        // Filtrando apenas os grupos onde o usuário está na lista de administradores
        options.value = response.data.filter(group => group.administrador.includes(user.uid))

        if (groupSelected && groupSelected?.id) {
          selectedOption.value = groupSelected
        } else {
          // console.log('nao selecionado')
          selectedOption.value = options.value[0]
        }
        groupUser.changeSelectedGroup(selectedOption.value)

        // console.log(selectedOption)
      } catch (error) {
        console.error('Erro ao buscar opções:', error)
      }
    }
    //TODO AI CLICAR EM SAIR DESLOGAR O USUARIO LIMPANDO A CHAVE DO USER
    // Observador para armazenar a opção selecionada no armazenamento local e atualizar a página
    watch(selectedOption, (newValue, oldValue) => {
      // console.log(`${oldValue} new value:::${newValue}`)
      if (newValue && oldValue && newValue.id !== oldValue.id) {
        groupUser.changeSelectedGroup(newValue)
        // window.location.reload() // Atualiza a página
        globalStore.incrementUpdate()
      }
    })

    onMounted(fetchOptions)

    return {
      selectedOption,
      options,
    }
  },
}
</script>
